/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h4: "h4",
    p: "p",
    a: "a",
    strong: "strong",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {Admonition} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h4, null, "Step 1. Create a refund instruction"), "\n", React.createElement(_components.p, null, "Send a POST request to the Devices test endpoint:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/devices/%7BserialNumber%7D/refund-instructions"
  }, "https://api.uat.payroc.com/v1/devices/{serialNumber}/refund-instructions")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example request")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n    \"operator\": \"jbloggs\",\n    \"processingTerminalId\": \"1021\",\n    \"order\": {\n        \"orderId\": \"4fd4-99bc\",\n        \"currency\": \"USD\",\n        \"amount\": 1000\n    },\n    \"customizationOptions\": {\n        \"entryMethod\": \"manualEntry\"\n    }\n}\n")), "\n", React.createElement(_components.h4, null, "Step 2. Retrieve the status of the refund instruction"), "\n", React.createElement(_components.p, null, "Send a GET request to the Refund Instructions test endpoint:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/refund-instructions/%7BrefundInstructionId%7D"
  }, "https://api.uat.payroc.com/v1/refund-instructions/{refundInstructionId}")), "\n", React.createElement(_components.p, null, "If the status of the refund instruction is ", React.createElement("code", null, "inProgress"), ", our gateway waits up to a minute for the status to change before it returns a response. Send GET requests until the value for ", React.createElement(_components.strong, null, "status"), " changes to ", React.createElement("code", null, "completed"), "."), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " If the status of the refund instruction is ", React.createElement("code", null, "failed"), " or ", React.createElement("code", null, "canceled"), ", contact your Payroc Integration Engineer.")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"refundInstructionId\": \"3743a9165d134678a9100ebba3b29597\",\n  \"status\": \"completed\",\n  \"link\": {\n    \"rel\": \"refunds\",\n    \"method\": \"GET\",\n    \"href\": \"https://api.payroc.com/v1/refunds/DD6ZDQU7L27\"\n  }\n}\n")), "\n", React.createElement(_components.h4, null, "Step 3. Retrieve the status of the refund instruction"), "\n", React.createElement(_components.p, null, "Send a GET request to the Refunds test endpoint:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/refunds/%7BrefundId%7D"
  }, "https://api.uat.payroc.com/v1/refunds/{refundId}")), "\n", React.createElement(_components.p, null, "Use the refundID that you received in the response from Step 2."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"paymentId\": \"DD6ZDQU7L2\",\n  \"processingTerminalId\": \"1021\",\n  \"operator\": \"Postman\",\n  \"order\": {\n    \"orderId\": \"4fd4-99bc\",\n    \"dateTime\": \"2023-07-26T16:42:25.018Z\",\n    \"description\": \"Example payment\",\n    \"amount\": 1000,\n    \"currency\": \"USD\"\n  },\n  \"customer\": {\n    \"shippingAddress\": {}\n  },\n  \"card\": {\n    \"type\": \"MasterCard\",\n    \"entryMethod\": \"keyed\",\n    \"cardNumber\": \"500165******0000\",\n    \"expiryDate\": \"0328\",\n    \"securityChecks\": {\n      \"cvvResult\": \"M\",\n      \"avsResult\": \"Y\"\n    }\n  },\n  \"transactionResult\": {\n    \"type\": \"sale\",\n    \"status\": \"ready\",\n    \"approvalCode\": \"OK3\",\n    \"authorizedAmount\": 1000,\n    \"currency\": \"USD\",\n    \"responseCode\": \"A\",\n    \"responseMessage\": \"OK3\"\n  }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
